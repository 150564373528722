import Layout from 'components/Layout';
import React from 'react';
import Clipart from 'components/ErrorPage/Clipart';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { useTranslation } from 'i18n';
import Title from 'components/Title/Title';
import { useRouter } from 'next/router';
import Button from 'components/Button/Button';
import PropTypes from 'prop-types';

export const ErrorTemplate = ({ errorcode = 500 }) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const x = useMotionValue(200);
  const y = useMotionValue(200);

  const rotateX = useTransform(y, [0, 1000], [-15, 15]);
  const rotateY = useTransform(x, [0, 1000], [15, -15]);

  function handleMouse(event) {
    const rect = event.currentTarget.getBoundingClientRect();

    x.set(event.clientX - rect.left);
    y.set(event.clientY - rect.top);
  }

  return (
    <motion.div onMouseMove={handleMouse}>
      <Layout>
        <div className="p-8 md:p-2 h-full w-full flex gap-12 md:gap-4 flex-col md:flex-row items-center justify-center max-w-screen-lg mx-auto">
          <motion.div
            className="w-full md:w-1/3 space-y-4"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className="space-y-1">
              <Title>{t(`httperror.${errorcode}.title`)}</Title>
              <Title size="h2">{t('httperror.oops')}</Title>
            </div>
            <p className="font-primary text-sm whitespace-pre-line">
              {t(`httperror.${errorcode}.message`)}
            </p>
            <div className="flex gap-2 flex-col md:flex-row">
              <Button appearance="primary" onClick={() => router.push('/')}>
                {t('httperror.go_to_home')}
              </Button>
              <Button appearance="tertiary" onClick={() => router.push('/help')}>
                {t('httperror.contact_us')}
              </Button>
            </div>
          </motion.div>
          <motion.div
            className="w-full md:w-2/3"
            // floating animation
            animate={{
              y: [0, -30, 10],
              transition: {
                duration: 5,
                ease: 'easeInOut',
                repeat: Infinity,
                repeatType: 'mirror',
              },
            }}
            style={{
              translateX: rotateX,
              translateY: rotateY,
            }}
          >
            <Clipart />
          </motion.div>
        </div>
      </Layout>
    </motion.div>
  );
};

ErrorTemplate.propTypes = {
  errorcode: PropTypes.number.isRequired,
};
