// set up the default hyperspread blue color to use if no color is provided, or if the color is not found
const HYPERSPREAD_DEFAULT_BLUE = '#004EBF';

const color = (value) => {
  // get css variable value from input
  if (typeof window !== 'undefined') {
    const colorValue =
      getComputedStyle(document.documentElement).getPropertyValue(value) ||
      HYPERSPREAD_DEFAULT_BLUE;

    return colorValue.trim();
  }

  return HYPERSPREAD_DEFAULT_BLUE;
};

export default color;
